const StockCode = ({ value }) => {
  const openNewTab = (stock) => {
    const host = window.location.host;
    const url = `${window.location.protocol}//${host}/finance-infor/${stock}`;
    window.open(url, "_blank");
  };

  return (
    <b
      onClick={() => openNewTab(value)}
      style={{
        cursor: "pointer",
        color: "#015096",
        fontSize: "1.1rem",
      }}>
      {value}
    </b>
  );
};

export default StockCode;
