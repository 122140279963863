import "./index.css";
import { Card, Row, Col, Typography } from "antd";

const { Title } = Typography;

const CardBox = ({ data }) => {
  return (
    <Card bordered={false} className="criclebox ">
      <div className="number">
        <Row align="middle" gutter={[24, 0]}>
          <Col xs={18}>
            <span>{data.today}</span>
            <Title level={3}>
              {data.title} <small className={data.bnb}>{data.persent}</small>
            </Title>
          </Col>
          <Col xs={6}>
            <div className="icon-box">{data.icon}</div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default CardBox;
