import "./index.css";
import logoUrl from "../../assets/img/fifree-logo.png";
import { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BankOutlined,
  ProjectOutlined,
  TagOutlined,
  HomeOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme, Flex, Drawer } from "antd";
import { routeData } from "../../services/routers";
import useWindowSize from "../../hooks/useWindowSize";
import SearchMain from "./SearchMain";
const { Header, Sider, Content, Footer } = Layout;

const MainLayout = () => {
  const { isMobileView } = useWindowSize();
  const [collapsed, setCollapsed] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const { pathname } = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([pathname]);

  const navigate = useNavigate();
  const handleClickMenu = ({ key }) => {
    navigate(`${key}`);
  };

  useEffect(() => {
    setSelectedKeys([pathname]);
  }, [pathname]);

  const menus = [
    {
      key: `/${routeData.dashboard}`,
      icon: <HomeOutlined />,
      label: <b>Dashboard</b>,
    },
    {
      key: `/${routeData.fundsPortfolio}`,
      icon: <ProjectOutlined />,
      label: <b>Danh mục quỹ</b>,
    },
    {
      key: `/${routeData.financeCategory}`,
      icon: <ProjectOutlined />,
      label: <b>Tài chính</b>,
    },
    {
      key: `/${routeData.stocks}`,
      icon: <TagOutlined />,
      label: <b>Cổ phiếu</b>,
    },
    {
      key: `/${routeData.funds}`,
      icon: <BankOutlined />,
      label: <b>Quỹ đầu tư</b>,
    },
    {
      key: `/${routeData.balanceSheet}`,
      icon: <BookOutlined />,
      label: <b>Quản lý cá nhân</b>,
    },
  ];

  if (!isMobileView) {
    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            background: colorBgContainer,
            position: "fixed",
            top: 0,
            bottom: 0,
            zIndex: 100,
          }}>
          <Flex style={{ paddingLeft: "0.3rem" }} align="center" onClick={() => setCollapsed((prev) => !prev)}>
            <img style={{ width: collapsed ? "100%" : "40%" }} alt="" src={logoUrl} />
            {!collapsed && <h1 style={{ margin: 0, fontSize: "2rem" }}>Fifree</h1>}
          </Flex>
          <Menu theme="light" mode="inline" selectedKeys={selectedKeys} onClick={handleClickMenu} items={menus} />
        </Sider>

        <Layout style={{ paddingLeft: collapsed ? "80px" : "200px" }}>
          <Header
            style={{
              padding: "0 1rem",
              background: colorBgContainer,
            }}>
            <Flex justify="space-between" align="center">
              <Button
                type="text"
                icon={
                  collapsed ? (
                    <MenuUnfoldOutlined
                      style={{
                        fontSize: "1.4rem",
                      }}
                    />
                  ) : (
                    <MenuFoldOutlined
                      style={{
                        fontSize: "1.4rem",
                      }}
                    />
                  )
                }
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  width: 64,
                  height: 64,
                }}
              />

              <SearchMain />
            </Flex>
          </Header>
          <Content
            className="fifree-content-container"
            style={{
              background: "none",
              borderRadius: borderRadiusLG,
            }}>
            <Outlet />
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}>
            Fifree ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    );
  } else {
    return (
      <>
        <Header
          style={{
            padding: "0 0.5rem",
            height: 40,
            background: colorBgContainer,
          }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setShowMobileMenu(true)}
          />
        </Header>
        <Content
          className="fifree-content-container"
          style={{
            background: "none",
            borderRadius: borderRadiusLG,
          }}>
          <Outlet />
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}>
          Fifree ©{new Date().getFullYear()}
        </Footer>
        <Drawer
          getContainer={document.getElementById("root")}
          style={{ padding: "0" }}
          styles={{ header: { pading: "0 0.5rem" } }}
          title={
            <Flex align="center">
              <img style={{ width: "5rem" }} alt="" src="./fifree-logo.png" />
              <h1 style={{ margin: 0, fontSize: "1.6rem" }}>Fifree</h1>
            </Flex>
          }
          width={"70%"}
          placement={"left"}
          closable={false}
          onClose={() => setShowMobileMenu(false)}
          open={showMobileMenu}>
          <Sider
            trigger={null}
            collapsible
            style={{
              background: colorBgContainer,
            }}>
            <Menu theme="light" mode="inline" selectedKeys={selectedKeys} onClick={handleClickMenu} items={menus} />
          </Sider>
        </Drawer>
      </>
    );
  }
};
export default MainLayout;
