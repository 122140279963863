import { createContext, useState, useContext, useEffect, useCallback } from "react";
import { getStockFollowing } from "../services";

const StocksContext = createContext();

export const StocksProvider = ({ children }) => {
  const [stockFollowingContext, setStockFollowingContext] = useState();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getStockFollowing();

      setStockFollowingContext(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <StocksContext.Provider
      value={{
        stockFollowingContext,
      }}>
      {children}
    </StocksContext.Provider>
  );
};

export const useStocksContext = () => useContext(StocksContext);
