import { useState } from "react";
import { Card, Button, Tabs } from "antd";
import ListBalanceSheet from "./ListIncome";
import AddBalanceSheet from "./Add";
import useWindowSize from "../../../hooks/useWindowSize";

const BalanceSheet = () => {
  const { isMobileView } = useWindowSize();
  const [isVisible, setIsVisible] = useState(false);
  const [isReloadList, setIsReloadList] = useState(false);

  const handleCloseModal = (isReload) => {
    if (isReload) {
      setIsReloadList((prevIsReloadList) => !prevIsReloadList);
    }
    setIsVisible(false);
  };

  const items = [
    {
      key: "1",
      label: <b>Danh sách chi tiêu</b>,
      children: <ListBalanceSheet isReload={isReloadList} />,
    },
  ];

  return (
    <Card
      bordered={false}
      title={<b className="form-title">Danh mục đầu tư các quỹ</b>}
      styles={{ body: { padding: "0 4px" } }}
      extra={
        <Button size="small" onClick={() => setIsVisible(true)}>
          Import
        </Button>
      }>
      {isVisible && <AddBalanceSheet isOpenModal={isVisible} onClose={handleCloseModal} />}

      <Tabs
        size="small"
        defaultActiveKey="1"
        items={items}
        style={{ padding: isMobileView ? "0 0.25rem" : "0 1.5rem", margin: 0 }}
        tabBarStyle={{ margin: 0 }}
      />
    </Card>
  );
};

export default BalanceSheet;
