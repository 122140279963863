const endpoint = "https://fifree-supabase-service.vercel.app/api";
// const endpoint = "http://localhost:8080/api";

const fetchBase = async (method, url, body, accessToken) => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    if (response.status === 204) {
      return {
        status: response.status,
      };
    }

    const results = await response.json();
    return results;
  } catch (error) {
    return { status: 204, error: error };
  }
};

//=================== STOCK ===================
// GET api/stock-following
export const getStockFollowing = async () => {
  return fetchBase("GET", `${endpoint}/stock/following`);
};

//=================== STOCK-FINANCE ===================
// POST api/stock-finance/get-financeinfo-by-stock
export const getFinanceInforByStock = async (stock, periods, type) => {
  return fetchBase("POST", `${endpoint}/stock-finance/get-financeinfo-by-stock`, {
    stock,
    periods,
    type,
  });
};

// POST api/stock-finance/get-financeinfo-by-stock
export const getFinanceInforByCategory = async (periods, type, category_id, report_component) => {
  return fetchBase("POST", `${endpoint}/stock-finance/get-financeinfo-by-category`, {
    periods,
    type,
    category_id,
    report_component,
  });
};

//=================== FUNDS ===================
// GET api/funds
export const getAllFunds = async () => {
  return fetchBase("GET", `${endpoint}/funds`);
};

// GET api/funds/funds-portfolio-stats
export const getFundPortfolioStats = async () => {
  return fetchBase("GET", `${endpoint}/funds/funds-portfolio-stats`);
};

//=================== FUNDS_PORTFOLIO ===================
export const checkExistFundsPortfolio = async (period, fundCode) => {
  return fetchBase("GET", `${endpoint}/funds-portfolio/check-exist?period=${period}&fund_code=${fundCode}`);
};

export const createListFundsPortfolio = async (listFundsPortfolio) => {
  const body = {
    funds_portfolio: listFundsPortfolio,
  };
  return fetchBase("POST", `${endpoint}/funds-portfolio/create-lists`, body);
};

export const getFundPortfolioByMonthStats = async (category = "quantity", startDate, endDate, searchStock) => {
  return fetchBase(
    "GET",
    `${endpoint}/funds-portfolio/stats-by-month?search_stock=${searchStock}&category=${category}&start_date=${startDate}&end_date=${endDate}`
  );
};

export const getFundPortfolioGroupByStock = async (category = "quantity", startDate, endDate, searchStock) => {
  return fetchBase(
    "GET",
    `${endpoint}/funds-portfolio/group-by-stock?search_stock=${searchStock}&category=${category}&start_date=${startDate}&end_date=${endDate}`
  );
};

export const getFundPortfolioByMonth = async (fundCode, period, searchStock) => {
  return fetchBase(
    "GET",
    `${endpoint}/funds-portfolio/by-month?fund_code=${fundCode}&period=${period}&search_stock=${searchStock}`
  );
};

export const getFundPortfolioByMonthGroupByStock = async (period, searchStock) => {
  return fetchBase(
    "GET",
    `${endpoint}/funds-portfolio/by-month-group-by-stock?period=${period}&search_stock=${searchStock}`
  );
};
