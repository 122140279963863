import { useState, useEffect, useCallback } from "react";
import { Flex, Card, Spin } from "antd";
import { getFundPortfolioGroupByStock } from "../../../../services";
import { getPeriods } from "../../../../services/common";
import ReactECharts from "echarts-for-react";
import StockCode from "../../../../components/common/StockCode";

const typeChart = "line"; //bar

const StockStatistic = ({ stock }) => {
  const [loading, setLoading] = useState(true);
  const [dataChart, setDataChart] = useState({});

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const start_date = new Date("2024/01/30").toISOString();
      const end_date = new Date().toISOString();

      const { data } = await getFundPortfolioGroupByStock("quantity", start_date, end_date, stock);

      if (data.length === 0) {
        setDataChart({});
        return;
      }

      const periods = getPeriods(start_date, end_date);

      const convertedDataChart = { xAxis: [], series: [] };

      periods.forEach((e) => {
        convertedDataChart.xAxis.push(e);
        convertedDataChart.series.push((data[0][e] / 1000).toFixed(0));
      });

      const optionsChart = {
        tooltip: { trigger: "item", z: 1000 },
        xAxis: {
          type: "category",
          data: convertedDataChart.xAxis,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: convertedDataChart.series,
            type: typeChart,
            barWidth: "50%",
            areaStyle: {},
          },
        ],
      };

      setDataChart(optionsChart);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [stock]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Card style={{ height: "50vh" }}>
      <Flex align="start" gap="1rem">
        <b className="report-component-title">Biểu đồ số lượng {<StockCode value={stock} />}</b>
        {loading && <Spin />}
      </Flex>

      {dataChart && <ReactECharts style={{ height: "45vh" }} option={dataChart} />}
    </Card>
  );
};
export default StockStatistic;
