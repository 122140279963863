import { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Table, Select, Spin, Flex, Card } from "antd";
import { getFinanceInforByStock } from "../../../services";
import {
  formatNumberWithCommas,
  initYearPeriods,
  initQuarterPeriods,
  chartTypes,
  getInforCompany,
} from "../../../services/common";
import EChartCustom from "../../../components/common/EChartCustom";
import useWindowSize from "../../../hooks/useWindowSize";
const BAR_CHART_WIDTH = 8;

const queryTypes = [
  {
    value: "YEAR",
    label: "Theo Năm",
  },
  {
    value: "QUARTER",
    label: "Theo Quý",
  },
];

const getPeriodsHeader = (periods) => {
  const header = [];
  for (let i = 0; i < periods.length; i++) {
    header.push({
      title: <b className="report-component-title">{periods[i]}</b>,
      dataIndex: periods[i],
      key: periods[i],
      align: "right",
      ellipsis: true,
      render: (item) => <b style={{ fontSize: "1rem" }}>{item ? formatNumberWithCommas(item) : 0}</b>,
    });
  }

  return header;
};

const initChartData = (rowData, periods, init = 1) => {
  const dataChart = {
    xAxis: [],
    series: [],
  };

  for (let i = 0; i < periods.length; i++) {
    dataChart.xAxis.push(periods[i]);

    if (rowData && rowData[periods[i]]) {
      dataChart.series.push(rowData[periods[i]] / init);
    } else dataChart.series.push(1);
  }

  return dataChart;
};

const FinanceInfor = () => {
  const { stock_code } = useParams();

  const { isMobileView } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [dataIncomeStatement, setDataIncomeStatement] = useState([]);
  const [dataBalanceSheet, setDataBalanceSheet] = useState([]);
  const [dataRatios, setDataRatios] = useState([]);

  const defaultYearPeriods = useMemo(() => initYearPeriods(), []);
  const defaultQuarterPeriods = useMemo(() => initQuarterPeriods(), []);

  const [periods, setPeriods] = useState(defaultYearPeriods);
  const [type, setType] = useState("YEAR");
  const [chartType, setChartType] = useState("bar");
  const [startPeriod, setStartPeriod] = useState(type === "YEAR" ? "2019" : "1/2023");
  const [endPeriod, setEndPeriod] = useState(type === "YEAR" ? "2024" : "4/2024");

  const stockInfor = getInforCompany(stock_code);
  const title = stockInfor ? (
    <b className="report-component-title" style={{ fontSize: "1.3rem" }}>
      {stockInfor.CompanyName} <b style={{ fontSize: "1.1rem" }}>( Ngành: {stockInfor.CategoryName})</b>
    </b>
  ) : (
    ""
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await getFinanceInforByStock(stock_code, periods, type);

      const converedIncomeStatement = [];
      const converedBalanceSheet = [];
      const converedRatios = [];

      for (let i = 0; i < data.length; i++) {
        const newItem = {
          ...data[i],
          key: i.toString(),
        };

        if (data[i].report_component_name.trim() === "Kết quả kinh doanh") {
          converedIncomeStatement.push(newItem);
        } else if (data[i].report_component_name.trim() === "Cân đối kế toán") {
          converedBalanceSheet.push(newItem);
        } else {
          converedRatios.push(newItem);
        }
      }

      setDataIncomeStatement(
        converedIncomeStatement.map((e, i) => {
          return { ...e, index: i };
        })
      );
      setDataBalanceSheet(
        converedBalanceSheet.map((e, i) => {
          return { ...e, index: i };
        })
      );
      setDataRatios(
        converedRatios.map((e, i) => {
          return { ...e, index: i };
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [type, stock_code, periods]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onChangeType = (value) => {
    if (value === "YEAR") {
      setStartPeriod("2019");
      setEndPeriod("2024");
      setPeriods(defaultYearPeriods);
    } else {
      setStartPeriod("1/2023");
      setEndPeriod("4/2024");

      const currentYear = new Date().getFullYear() - 2;
      setPeriods(initQuarterPeriods(currentYear));
    }

    setType(value);
  };

  useEffect(() => {
    let newPeriods;
    if (type === "YEAR") {
      const startIndex = defaultYearPeriods.indexOf(startPeriod);
      const endIndex = defaultYearPeriods.indexOf(endPeriod);

      newPeriods = defaultYearPeriods.slice(startIndex, endIndex + 1);
    } else {
      const startIndex = defaultQuarterPeriods.indexOf(startPeriod);
      const endIndex = defaultQuarterPeriods.indexOf(endPeriod);

      newPeriods = defaultQuarterPeriods.slice(startIndex, endIndex + 1);
    }

    setPeriods(newPeriods);
  }, [startPeriod, endPeriod, type, defaultYearPeriods, defaultQuarterPeriods]);

  const monthsHeader = getPeriodsHeader(periods, type);

  const columnsIncomeStatement = [
    {
      title: <b className="report-component-title">Kết quả kinh doanh</b>,
      dataIndex: "report_type_name",
      key: "report_type_name",
      width: "20%",
      fixed: "left",
      ellipsis: true,
      render: (data) => <b>{data}</b>,
    },
    ...monthsHeader,
    {
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      fixed: "right",
      width: "15%",
      render: (item) => {
        let rowData = dataIncomeStatement[item];

        const dataChart = initChartData(rowData, periods, 1000);

        return (
          <EChartCustom
            data={dataChart}
            type={chartType}
            barWidth={BAR_CHART_WIDTH}
            style={{ height: isMobileView ? "7rem" : "5rem", width: isMobileView ? "20vw" : "100%" }}
          />
        );
      },
    },
  ];

  const columnsBalanceSheet = [
    {
      title: <b className="report-component-title">Cân đối kế toán</b>,
      dataIndex: "report_type_name",
      key: "report_type_name",
      width: "20%",
      fixed: "left",
      ellipsis: true,
      render: (data) => <b>{data}</b>,
    },
    ...monthsHeader,
    {
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      fixed: "right",
      width: "15%",
      render: (item) => {
        let rowData = dataBalanceSheet[item];

        const dataChart = initChartData(rowData, periods, 1000);

        return (
          <EChartCustom
            data={dataChart}
            type={chartType}
            barWidth={BAR_CHART_WIDTH}
            style={{ height: isMobileView ? "7rem" : "5rem", width: isMobileView ? "20vw" : "100%" }}
          />
        );
      },
    },
  ];

  const columnsRatios = [
    {
      title: <b className="report-component-title">Chỉ số tài chính</b>,
      dataIndex: "report_type_name",
      key: "report_type_name",
      width: "20%",
      fixed: "left",
      ellipsis: true,
      render: (data) => <b>{data}</b>,
    },
    ...monthsHeader,
    {
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      fixed: "right",
      width: "15%",
      render: (item) => {
        let rowData = [];
        rowData = dataRatios[item];

        const dataChart = initChartData(rowData, periods, 1);

        return (
          <EChartCustom
            data={dataChart}
            type={chartType}
            barWidth={BAR_CHART_WIDTH}
            style={{ height: isMobileView ? "7rem" : "5rem", width: isMobileView ? "20vw" : "100%" }}
          />
        );
      },
    },
  ];

  const yearPeriodOptions = defaultYearPeriods
    .map((e) => {
      return {
        value: e,
        label: e,
      };
    })
    .reverse();

  const quarterPeriodOptions = defaultQuarterPeriods
    .map((e) => {
      return {
        value: e,
        label: e,
      };
    })
    .reverse();

  return (
    <Card bordered={false} title={title}>
      <Flex justify="space-between" align="center" style={{ flexWrap: "wrap", width: "100%" }}>
        <Flex justify="start" gap={isMobileView ? 6 : 12}>
          <Select
            placeholder="Loại thông tin"
            value={type}
            onChange={onChangeType}
            options={queryTypes}
            style={{ width: 150 }}
          />
          <Select
            placeholder="Từ kỳ"
            value={startPeriod}
            onChange={(value) => setStartPeriod(value)}
            options={type === "YEAR" ? yearPeriodOptions : quarterPeriodOptions}
            style={{ width: 150 }}
          />
          <Select
            placeholder="Đến kỳ"
            value={endPeriod}
            onChange={(value) => setEndPeriod(value)}
            options={type === "YEAR" ? yearPeriodOptions : quarterPeriodOptions}
            style={{ width: 150 }}
          />
          <Select
            placeholder="Loại biểu đồ"
            value={chartType}
            onChange={(value) => setChartType(value)}
            options={chartTypes}
            style={{ width: 150 }}
          />
        </Flex>

        <Flex gap="1rem" justify="end">
          {loading && <Spin />}
          <i>Đơn vị: Triệu đồng</i>
        </Flex>
      </Flex>
      <br></br>
      <Table
        size="small"
        columns={columnsIncomeStatement}
        dataSource={dataIncomeStatement}
        className="ant-border-space"
        pagination={false}
      />
      <Table
        size="small"
        columns={columnsBalanceSheet}
        dataSource={dataBalanceSheet}
        className="ant-border-space"
        pagination={false}
      />
      <Table
        size="small"
        columns={columnsRatios}
        dataSource={dataRatios}
        className="ant-border-space"
        pagination={false}
      />
    </Card>
  );
};

export default FinanceInfor;
