import { useState, useEffect, useCallback, useMemo } from "react";
import { Table, Select, Spin, Flex, Card } from "antd";
import { getFinanceInforByCategory } from "../../../services";
import { formatNumberWithCommas, initYearPeriods, initQuarterPeriods, chartTypes } from "../../../services/common";
import { category_options } from "../../../utils/data";
import EChartCustom from "../../../components/common/EChartCustom";
import useWindowSize from "../../../hooks/useWindowSize";
import StockCode from "../../../components/common/StockCode";
const BAR_CHART_WIDTH = 8;

const queryTypes = [
  {
    value: "YEAR",
    label: "Theo Năm",
  },
  {
    value: "QUARTER",
    label: "Theo Quý",
  },
];

const reportComponentOptions = [
  {
    value: "Income Statement",
    label: "Kết quả kinh doanh",
  },
  {
    value: "Balance Sheet",
    label: "Cân đối kế toán",
  },
  {
    value: "RATIOS",
    label: "Chỉ số tài chính",
  },
];

const getPeriodsHeader = (periods) => {
  const header = [];
  for (let i = 0; i < periods.length; i++) {
    header.push({
      title: <b className="report-component-title">{periods[i]}</b>,
      dataIndex: periods[i],
      key: periods[i],
      align: "right",
      ellipsis: true,
      render: (item) => (
        <b style={{ fontSize: "1rem" }}>{item && item.value ? formatNumberWithCommas(item.value) : 0}</b>
      ),
      sorter: (a, b) => a[periods[i]].value - b[periods[i]].value,
    });
  }

  return header;
};

const initChartData = (rowData, periods, init = 1) => {
  const dataChart = {
    xAxis: [],
    series: [],
  };

  for (let i = 0; i < periods.length; i++) {
    dataChart.xAxis.push(periods[i]);

    if (rowData && rowData[periods[i]]) {
      dataChart.series.push(rowData[periods[i]].value / init);
    } else dataChart.series.push(0);
  }

  return dataChart;
};

const getGrowIndicator = (data, periods) => {
  let count = 0;

  for (let i = periods.length - 1; i >= 0; i--) {
    if (data[periods[i]]) {
      if (data[periods[i - 1]]) {
        if (data[periods[i]].value > data[periods[i - 1]].value) {
          count++;
        } else {
          return count;
        }
      } else {
        return count + 1;
      }
    } else return count;
  }

  return count;
};

const FinanceCategory = () => {
  const { isMobileView } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [dataShow, setDataShow] = useState([]);

  const [category, setCategory] = useState(347);
  const [reportType, setReportType] = useState(null);
  const [reportTypeOptions, setReportTypeOptions] = useState([]);
  const [reportComponent, setReportComponent] = useState("Income Statement");

  const defaultYearPeriods = useMemo(() => initYearPeriods(), []);
  const defaultQuarterPeriods = useMemo(() => initQuarterPeriods(), []);

  const [periods, setPeriods] = useState(defaultYearPeriods);
  const [type, setType] = useState("YEAR");
  const [chartType, setChartType] = useState("bar");
  const [startPeriod, setStartPeriod] = useState(type === "YEAR" ? "2019" : "1/2023");
  const [endPeriod, setEndPeriod] = useState(type === "YEAR" ? "2024" : "4/2024");

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getFinanceInforByCategory(periods, type, category, reportComponent);

      const converedData = [];
      for (let i = 0; i < data.length; i++) {
        let temp = data[i];
        for (let j = 0; j < periods.length; j++) {
          if (temp[periods[j]]) {
            temp = {
              ...temp,
              [periods[j]]: {
                value: temp[periods[j]],
                prev: 0,
              },
            };

            if (j === 0) {
              temp[periods[j]].prev = temp[periods[j]].value;
            } else if (temp[periods[j - 1]]) {
              temp[periods[j]].prev = temp[periods[j - 1]].value;
            }
          }
        }

        const growIndicator = getGrowIndicator(temp, periods);

        converedData.push({
          ...temp,
          growIndicator,
        });
      }

      setOriginalData(converedData);
      const tempDataShow = [];
      const report_type_names = [];

      converedData.forEach((item) => {
        if (report_type_names.indexOf(item.report_type_name.trim()) === -1) {
          report_type_names.push(item.report_type_name.trim());
        }

        if (item.report_type_name.trim() === report_type_names[0])
          tempDataShow.push({
            ...item,
            key: item.stock_code,
          });
      });

      setReportTypeOptions(
        report_type_names.map((e) => {
          return { value: e, label: e };
        })
      );

      setReportType(report_type_names[0]);
      setDataShow(tempDataShow);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [type, periods, category, reportComponent]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let newPeriods;
    if (type === "YEAR") {
      const startIndex = defaultYearPeriods.indexOf(startPeriod);
      const endIndex = defaultYearPeriods.indexOf(endPeriod);

      newPeriods = defaultYearPeriods.slice(startIndex, endIndex + 1);
    } else {
      const startIndex = defaultQuarterPeriods.indexOf(startPeriod);
      const endIndex = defaultQuarterPeriods.indexOf(endPeriod);

      newPeriods = defaultQuarterPeriods.slice(startIndex, endIndex + 1);
    }

    setPeriods(newPeriods);
  }, [startPeriod, endPeriod, type, defaultYearPeriods, defaultQuarterPeriods]);

  const onChangeType = (value) => {
    if (value === "YEAR") {
      setStartPeriod("2019");
      setEndPeriod("2024");
      setPeriods(defaultYearPeriods);
    } else {
      setStartPeriod("1/2023");
      setEndPeriod("4/2024");

      const currentYear = new Date().getFullYear() - 2;
      setPeriods(initQuarterPeriods(currentYear));
    }

    setType(value);
  };

  const onChangeReportType = (value) => {
    setReportType(value);
    const newDataShow = [];
    originalData.forEach((item) => {
      if (item.report_type_name.trim() === value) {
        newDataShow.push({ ...item, key: item.stock_code });
      }
    });
    setDataShow(newDataShow);
  };

  const periodsHeader = getPeriodsHeader(periods, type);

  const columns = [
    {
      title: <b className="report-component-title">I</b>,
      dataIndex: "stock_code",
      key: "stock_code",
      width: "7%",
      fixed: "center",
      ellipsis: true,
      render: (_data, _a, i) => <b>{i + 1}</b>,
    },
    {
      title: <b className="report-component-title">Mã</b>,
      dataIndex: "stock_code",
      key: "stock_code",
      width: "7%",
      fixed: "left",
      ellipsis: true,
      render: (value) => <StockCode value={value} />,
    },
    ...periodsHeader,
    {
      title: "I",
      dataIndex: "growIndicator",
      key: "growIndicator",
      align: "center",
      fixed: "right",
      width: "5%",
      ellipsis: true,
      render: (data) => <b style={{ color: "#015096" }}>{data}</b>,
      sorter: (a, b) => a.growIndicator - b.growIndicator,
    },
    {
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      ellipsis: true,
      fixed: "right",
      width: "15%",
      render: (value) => {
        let rowData = dataShow.find((e) => e.stock_code === value);

        const dataChart = initChartData(rowData, periods, 1000);

        return (
          <EChartCustom
            data={dataChart}
            type={chartType}
            barWidth={BAR_CHART_WIDTH}
            style={{ height: isMobileView ? "7rem" : "5rem", width: isMobileView ? "20vw" : "100%" }}
          />
        );
      },
    },
  ];

  const yearPeriodOptions = defaultYearPeriods
    .map((e) => {
      return {
        value: e,
        label: e,
      };
    })
    .reverse();

  const quarterPeriodOptions = defaultQuarterPeriods
    .map((e) => {
      return {
        value: e,
        label: e,
      };
    })
    .reverse();

  return (
    <Card bordered={false} title={<b className="form-title">Phân tích tài chính doanh nghiệp theo ngành</b>}>
      <Flex justify="space-between" align="center" style={{ flexWrap: "wrap", width: "100%" }}>
        <Flex justify="start" gap={isMobileView ? 6 : 12}>
          <Select placeholder="Kỳ" value={type} onChange={onChangeType} options={queryTypes} style={{ width: 120 }} />
          <Select
            placeholder="Ngành"
            value={category}
            onChange={(value) => setCategory(value)}
            options={category_options}
            style={{ width: 160 }}
          />
          <Select
            placeholder="Nhóm báo cáo"
            value={reportComponent}
            onChange={(value) => setReportComponent(value)}
            options={reportComponentOptions}
            style={{ width: 180 }}
          />
          <Select
            placeholder="Loại báo cáo"
            value={reportType}
            onChange={onChangeReportType}
            options={reportTypeOptions}
            style={{ width: 190 }}
          />
          <Select
            placeholder="Từ kỳ"
            value={startPeriod}
            onChange={(value) => setStartPeriod(value)}
            options={type === "YEAR" ? yearPeriodOptions : quarterPeriodOptions}
            style={{ width: 90 }}
          />
          <Select
            placeholder="Đến kỳ"
            value={endPeriod}
            onChange={(value) => setEndPeriod(value)}
            options={type === "YEAR" ? yearPeriodOptions : quarterPeriodOptions}
            style={{ width: 90 }}
          />
          <Select
            placeholder="Loại biểu đồ"
            value={chartType}
            onChange={(value) => setChartType(value)}
            options={chartTypes}
            style={{ width: 70 }}
          />
        </Flex>

        {reportComponent !== "RATIOS" && (
          <Flex gap="1rem" justify="end">
            {loading && <Spin />}
            <i>Đơn vị: Triệu đồng</i>
          </Flex>
        )}
      </Flex>
      <br></br>
      <Table size="small" columns={columns} dataSource={dataShow} className="ant-border-space" pagination={false} />
    </Card>
  );
};

export default FinanceCategory;
