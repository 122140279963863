import { all_stocks_data } from "../utils/data";

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const formatToPercentage = (number) => {
  return `${(number * 100).toFixed(2)}`;
};

export const chartTypes = [
  {
    value: "line",
    label: "Line",
  },
  {
    value: "bar",
    label: "Bar",
  },
];

export const formatISOToUTC7 = (isoString) => {
  const date = new Date(isoString);
  const offset = 7 * 60 * 60 * 1000;
  const utc7Date = new Date(date.getTime() + offset);

  const day = String(utc7Date.getUTCDate()).padStart(2, "0");
  const month = String(utc7Date.getUTCMonth() + 1).padStart(2, "0");
  const year = utc7Date.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};

export const excelDateToJSDate = (excelSerial) => {
  const excelEpoch = new Date(1900, 0, 1);
  const daysOffset = excelSerial - 2;
  return new Date(excelEpoch.getTime() + daysOffset * 24 * 60 * 60 * 1000);
};

export const formatDateToJSDateToShow = (string) => {
  const date = excelDateToJSDate(string);
  const offset = 7 * 60 * 60 * 1000;
  const utc7Date = new Date(date.getTime() + offset);

  const day = String(utc7Date.getUTCDate()).padStart(2, "0");
  const month = String(utc7Date.getUTCMonth() + 1).padStart(2, "0");
  const year = utc7Date.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};

export const formatDateToShow = (string) => {
  const date = new Date(string);
  const offset = 7 * 60 * 60 * 1000;
  const utc7Date = new Date(date.getTime() + offset);

  const day = String(utc7Date.getUTCDate()).padStart(2, "0");
  const month = String(utc7Date.getUTCMonth() + 1).padStart(2, "0");
  const year = utc7Date.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};

export const getPeriods = (start_date, end_date) => {
  const startDate = start_date ? new Date(start_date) : new Date("2024/01/30");
  const startMonth = startDate.getMonth() + 1;
  const startYear = startDate.getFullYear();

  const endDate = end_date ? new Date(end_date) : new Date();
  const currentMonth = endDate.getMonth() + 1;
  const currentYear = endDate.getFullYear();

  let resutlData = [];

  for (let year = startYear; year <= currentYear; year++) {
    for (let month = 1; month <= 12; month++) {
      if (year === startYear) {
        if (month >= startMonth) resutlData.push(`${month}/${year}`);
      } else {
        if (year === currentYear && month > currentMonth) {
          return resutlData;
        }
        resutlData.push(`${month}/${year}`);
      }
    }
  }

  return resutlData;
};

export const initYearPeriods = (start = 2019) => {
  const currentYear = new Date().getFullYear() - 1;
  const results = [];

  for (let i = start; i <= currentYear; i++) results.push(i.toString());

  return results;
};

export const initQuarterPeriods = (start = 2016) => {
  const currentYear = new Date().getFullYear() - 1;
  const results = [];

  for (let i = start; i <= currentYear; i++) for (let j = 1; j <= 4; j++) results.push(`${j}/${i.toString()}`);

  return results;
};

export const getInforCompany = (stock) => {
  return all_stocks_data.find((e) => e.Symbol.trim().toLocaleLowerCase() === stock.toLocaleLowerCase());
};

export { formatNumberWithCommas, formatToPercentage };
