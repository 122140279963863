import { Card, Tabs } from "antd";
import { FundsProvider } from "../../../context/FundsContext";
import ListFunds from "./ListFunds";
import AdvanceFunds from "./AdvanceFunds";

const Funds = () => {
  const items = [
    {
      key: "0",
      label: <b>Phân tích theo tháng</b>,
      children: <AdvanceFunds />,
    },
    {
      key: "1",
      label: <b>Thông tin chung</b>,
      children: <ListFunds />,
    },
  ];

  return (
    <FundsProvider>
      <Card
        bordered={false}
        title={<b className="form-title">Danh sách quỹ dầu tư</b>}
        styles={{ body: { padding: "0 4px" } }}>
        <Tabs
          size="small"
          defaultActiveKey="0"
          items={items}
          style={{ padding: "0 1.5rem", margin: 0 }}
          tabBarStyle={{ margin: 0 }}
        />
      </Card>
    </FundsProvider>
  );
};

export default Funds;
