import { useState } from "react";
import { Flex, Table, Tag } from "antd";
import { formatNumberWithCommas, formatToPercentage } from "../../../../services/common";
import { useFundsContext } from "../../../../context/FundsContext";

const ListFunds = () => {
  const { fundsContext } = useFundsContext();

  const [sortedInfo, setSortedInfo] = useState({});

  const handleChange = (_pagination, _filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: <b className="report-component-title">I</b>,
      align: "center",
      ellipsis: true,
      width: "8%",
      fixed: "left",
      render: (_text, _record, index) => <b>{index + 1}</b>,
    },
    {
      title: <b className="report-component-title">Tổ chức</b>,
      dataIndex: "organization_name",
      key: "organization_name",
      ellipsis: true,
      sorter: (a, b) => {
        return a.organization_name ? a.organization_name.localeCompare(b.organization_name) : 1;
      },
      sortOrder: sortedInfo.columnKey === "organization_name" ? sortedInfo.order : null,
      render: (value) => <b>{value}</b>,
    },

    {
      title: <b className="report-component-title">Mã quỹ</b>,
      dataIndex: "fund_code",
      key: "fund_code",
      align: "center",
      ellipsis: true,
      width: "10%",
      sorter: (a, b) => {
        return a.fund_code ? a.fund_code.localeCompare(b.fund_code) : 1;
      },
      sortOrder: sortedInfo.columnKey === "fund_code" ? sortedInfo.order : null,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <b className="report-component-title">Tên quỹ</b>,
      dataIndex: "fund_name",
      key: "fund_name",
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <b className="report-component-title">Trạng thái</b>,
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "10%",
      ellipsis: true,
      render: (value) => {
        if (!value) {
          return <Tag color="red">inactive</Tag>;
        } else {
          return <Tag color="green">active</Tag>;
        }
      },
      sorter: (a, b) => {
        return a.status - b.status;
      },
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
    },
    {
      title: <b className="report-component-title">Tổng tài sản</b>,
      dataIndex: "total_asset",
      key: "total_asset",
      align: "center",
      sorter: (a, b) => a.total_asset - b.total_asset,
      sortOrder: sortedInfo.columnKey === "total_asset" ? sortedInfo.order : null,
      ellipsis: true,
      render: (numberString) => <b>{formatNumberWithCommas(numberString)}</b>,
    },
    {
      title: <b className="report-component-title">Phí (12 tháng)</b>,
      dataIndex: "fee",
      key: "fee",
      align: "center",
      sorter: (a, b) => a.fee - b.fee,
      sortOrder: sortedInfo.columnKey === "fee" ? sortedInfo.order : null,
      ellipsis: true,
      render: (numberString) => <b>{formatToPercentage(numberString)}</b>,
    },
    {
      title: <b className="report-component-title">Ngày thành lập</b>,
      dataIndex: "establish_date",
      key: "establish_date",
      align: "center",
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
  ];

  return (
    <>
      <Flex justify="end">
        <h4>Tổng: {fundsContext.length}</h4>
      </Flex>
      <Table
        columns={columns}
        dataSource={fundsContext}
        onChange={handleChange}
        className="ant-border-space"
        pagination={{
          pageSize: 50,
          position: ["bottomCenter"],
        }}
        scroll={{
          y: "70vh",
        }}
      />
    </>
  );
};

export default ListFunds;
