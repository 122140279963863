import { useState, useEffect, useCallback } from "react";
import { Table, Spin, Flex, Card, Tag } from "antd";
import { getPeriods } from "../../../../services/common";
import { getFundPortfolioGroupByStock } from "../../../../services";
import { all_stocks_data } from "../../../../utils/data";
import { useStocksContext } from "../../../../context/StocksContext";
import EChartCustom from "../../../../components/common/EChartCustom";
import StockCode from "../../../../components/common/StockCode";
const initChartData = (rowData, periods) => {
  const dataChart = {
    xAxis: [],
    series: [],
  };

  for (let i = 0; i < periods.length; i++) {
    dataChart.xAxis.push(periods[i]);

    if (rowData && rowData[periods[i]]) {
      dataChart.series.push(Math.floor(rowData[periods[i]] / 1000));
    } else dataChart.series.push(1);
  }

  return dataChart;
};

const StockFollowing = () => {
  const [loading, setLoading] = useState(true);
  const [dataGroup, setDataGroup] = useState([]);
  const [periods, setPeriods] = useState([]);
  const { stockFollowingContext } = useStocksContext();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const start_date = new Date("2024/01/30").toISOString();
      const end_date = new Date().toISOString();
      setPeriods(getPeriods(start_date, end_date));
      const stocks = stockFollowingContext.map((e) => e.stock_code).join(",");

      const { data } = await getFundPortfolioGroupByStock("quantity", start_date, end_date, stocks);

      const finalData = [];
      stockFollowingContext.forEach((e) => {
        const stock = all_stocks_data.find((s) => s.Symbol === e.stock_code);
        const dataItem = data.find((i) => i.stock_code === e.stock_code);

        finalData.push({
          key: e.stock_code,
          status: e.action,
          category: stock.CategoryName ?? "",
          ...dataItem,
        });
      });

      setDataGroup(finalData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [stockFollowingContext]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns_group = [
    {
      title: <span className="text-secondary-color">Mã</span>,
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      ellipsis: true,
      width: "14%",
      render: (value) => <StockCode value={value} />,
    },
    {
      title: <span className="text-secondary-color">Status</span>,
      dataIndex: "status",
      key: "status",
      align: "left",
      ellipsis: true,
      width: "17%",
      render: (value) => {
        if (value === "HOLDING") return <Tag color="success">Nắm giữ</Tag>;
        else if (value === "FOLLOWING") return <Tag color="warning">Theo dõi</Tag>;
      },
    },
    {
      title: <span className="text-secondary-color">Ngành</span>,
      dataIndex: "category",
      key: "category",
      align: "left",
      width: "20%",
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <span className="text-secondary-color">Chart</span>,
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      ellipsis: true,
      fixed: "right",
      render: (item) => {
        const rowData = dataGroup.find((e) => e.stock_code === item);
        const dataChart = initChartData(rowData, periods);

        return <EChartCustom data={dataChart} type={"line"} style={{ height: "6rem" }} />;
      },
    },
  ];

  return (
    <Card style={{ height: "70vh" }}>
      <Flex gap={"1rem"} align="center" justify="">
        <b className="report-component-title">Danh sách theo dõi</b>
        {loading && <Spin />}
      </Flex>
      <br></br>
      <Table
        columns={columns_group}
        dataSource={dataGroup}
        className="ant-border-space"
        pagination={false}
        scroll={{
          y: "55vh",
        }}
      />
    </Card>
  );
};
export default StockFollowing;
