import { useState, useEffect, useCallback } from "react";
import { Table, Select, Space, Input, Checkbox, Spin, Flex, DatePicker } from "antd";
import dayjs from "dayjs";
import { getFundPortfolioByMonth, getFundPortfolioByMonthGroupByStock } from "../../../../services";
import { formatNumberWithCommas, formatToPercentage } from "../../../../services/common";
import { useFundsContext } from "../../../../context/FundsContext";
import { all_stocks_data } from "../../../../utils/data";
import StockCode from "../../../../components/common/StockCode";
const monthFormat = "MM/YYYY";

const ListPortfolios = ({ isReload }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});

  const { fundsContext } = useFundsContext();

  // filter option
  const [fundSelected, setFundSelected] = useState("");
  const [time, setTime] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [checked, setChecked] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const date = new Date(time);
      const period = `${date.getMonth() + 1}/${date.getFullYear()}`;

      let res;
      if (checked) {
        res = await getFundPortfolioByMonthGroupByStock(period, searchValue);
      } else {
        res = await getFundPortfolioByMonth(fundSelected, period, searchValue);
      }

      const { data } = res;

      if (checked) {
        const converedData = data.map((e, index) => {
          const stock = all_stocks_data.find((s) => s.Symbol === e.stock_code);

          return {
            key: index.toString(),
            index: index + 1,
            stock_code: e.stock_code,
            count: e.fund_code.split(",").length,
            organizations: e.fund_code,
            quantity: e.total_quantity,
            value: e.total_value,
            company_name: stock.CompanyName ?? "",
            category: stock.CategoryName ?? "",
          };
        });

        setDataGroup(converedData);
      } else {
        const converedData = data.map((e, index) => {
          const stock = all_stocks_data.find((s) => s.Symbol === e.stock_code.trim());

          return {
            ...e,
            key: index.toString(),
            index: index + 1,
            fund_code: e.fund_code,
            company_name: stock ? stock.CompanyName : "",
            category: stock ? stock.CategoryName : "",
          };
        });
        setData(converedData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [fundSelected, searchValue, checked, time]);

  useEffect(() => {
    fetchData();
  }, [isReload, fetchData]);

  const handleChange = (_pagination, _filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: <b className="report-component-title">I</b>,
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: "6%",
      fixed: "left",
      render: (_text, _record, index) => <b>{index + 1}</b>,
    },
    {
      title: <b className="report-component-title">Mã</b>,
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      ellipsis: true,
      width: "7%",
      fixed: "left",
      render: (value) => <StockCode value={value} />,
    },
    {
      title: <b className="report-component-title">Quỹ</b>,
      dataIndex: "fund_code",
      key: "fund_code",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
      render: (value) => <b>{value}</b>,
    },
    {
      title: <b className="report-component-title">Ngành</b>,
      dataIndex: "category",
      key: "category",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
      sorter: (a, b) => {
        return a.category ? a.category.localeCompare(b.category) : 1;
      },
      sortOrder: sortedInfo.columnKey === "category" ? sortedInfo.order : null,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <b className="report-component-title">SLCP</b>,
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      ellipsis: true,
      sorter: (a, b) => a.quantity - b.quantity,
      sortOrder: sortedInfo.columnKey === "quantity" ? sortedInfo.order : null,
      render: (numberString) => <b>{formatNumberWithCommas(numberString)}</b>,
    },
    {
      title: <b className="report-component-title">Giá TT</b>,
      dataIndex: "market_price",
      key: "market_price",
      align: "center",
      width: "10%",
      ellipsis: true,
      sorter: (a, b) => a.market_price - b.market_price,
      sortOrder: sortedInfo.columnKey === "market_price" ? sortedInfo.order : null,
      render: (numberString) => <b>{formatNumberWithCommas(Math.floor(numberString / 1000))}</b>,
    },
    {
      title: <b className="report-component-title">Giá trị</b>,
      dataIndex: "value",
      key: "value",
      align: "center",
      width: "10%",
      ellipsis: true,
      sorter: (a, b) => a.value - b.value,
      sortOrder: sortedInfo.columnKey === "value" ? sortedInfo.order : null,
      render: (numberString) => <b>{formatNumberWithCommas(Math.floor(numberString / 1000000))}</b>,
    },
    {
      title: <b className="report-component-title">Tỉ trọng</b>,
      dataIndex: "portfolio_rate",
      key: "portfolio_rate",
      align: "center",
      width: "10%",
      ellipsis: true,
      sorter: (a, b) => a.portfolio_rate - b.portfolio_rate,
      sortOrder: sortedInfo.columnKey === "portfolio_rate" ? sortedInfo.order : null,
      render: (numberString) => <b>{formatToPercentage(numberString)}</b>,
    },
    {
      title: <b className="report-component-title">Tên dầy đủ</b>,
      dataIndex: "company_name",
      key: "company_name",
      align: "left",
      ellipsis: true,
      width: "15%",
    },
  ];

  const columns_group = [
    {
      title: <b className="report-component-title">I</b>,
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: "5%",
      fixed: "left",
      render: (_text, _record, index) => index + 1,
    },
    {
      title: <b className="report-component-title">Mã</b>,
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      ellipsis: true,
      width: "7%",
      fixed: "left",
      render: (value) => <StockCode value={value} />,
    },
    {
      title: <b className="report-component-title">Ngành</b>,
      dataIndex: "category",
      key: "category",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
      sorter: (a, b) => {
        return a.category ? a.category.localeCompare(b.category) : 1;
      },
      sortOrder: sortedInfo.columnKey === "category" ? sortedInfo.order : null,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <b className="report-component-title">Tần suất</b>,
      dataIndex: "count",
      key: "count",
      align: "center",
      width: "8%",
      ellipsis: true,
      sorter: (a, b) => a.count - b.count,
      sortOrder: sortedInfo.columnKey === "count" ? sortedInfo.order : null,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <b className="report-component-title">Quỹ</b>,
      dataIndex: "organizations",
      key: "organizations",
      align: "left",
      width: "24%",
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <b className="report-component-title">Tổng SL</b>,
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      width: "8%",
      ellipsis: true,
      sorter: (a, b) => a.quantity - b.quantity,
      sortOrder: sortedInfo.columnKey === "quantity" ? sortedInfo.order : null,
      render: (numberString) => <b>{formatNumberWithCommas(Math.floor(numberString))}</b>,
    },
    {
      title: <b className="report-component-title">Tổng GT</b>,
      dataIndex: "value",
      key: "value",
      align: "center",
      width: "8%",
      ellipsis: true,
      sorter: (a, b) => a.value - b.value,
      sortOrder: sortedInfo.columnKey === "value" ? sortedInfo.order : null,
      render: (numberString) => <b>{formatNumberWithCommas(Math.floor(numberString / 1000000))}</b>,
    },
    {
      title: <b className="report-component-title">Tên dầy đủ</b>,
      dataIndex: "company_name",
      key: "company_name",
      align: "left",
      ellipsis: true,
      width: "15%",
    },
  ];

  return (
    <>
      <Flex justify="space-between">
        <Space size={12}>
          <DatePicker
            defaultValue={dayjs(dayjs(new Date()).format(monthFormat), monthFormat)}
            format={monthFormat}
            picker="month"
            onChange={(e) => setTime(e)}
          />
          <Select
            allowClear
            showSearch
            placeholder="Quỹ đầu tư"
            onChange={(value) => setFundSelected(value)}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={fundsContext}
            style={{ width: 250 }}
            disabled={checked}
          />

          <Input
            allowClear
            placeholder="Mã cổ phiếu"
            style={{ width: 150, fontWeight: "400" }}
            onPressEnter={(e) => setSearchValue(e.target.value)}
          />
          <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
            Thống kê tần suất
          </Checkbox>
          {loading && <Spin />}
        </Space>

        <h4>Tổng: {checked ? dataGroup.length : data.length}</h4>
      </Flex>
      <Table
        columns={checked ? columns_group : columns}
        dataSource={checked ? dataGroup : data}
        onChange={handleChange}
        className="ant-border-space"
        pagination={{
          pageSize: 50,
          position: ["bottomCenter"],
        }}
        scroll={{
          x: "max-content",
          y: "80vh",
        }}
      />
    </>
  );
};
export default ListPortfolios;
