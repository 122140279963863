import "./App.css";
import "./antd-overrides.css";
import { Routes, Route } from "react-router-dom";
import { routeData } from "./services/routers";
import MainLayout from "./components/layout";
import Dashboard from "./pages/Investment/Dashboard";
import Funds from "./pages/Investment/Funds";
import FundsPortfolio from "./pages/Investment/FundsPortfolio";
import Stocks from "./pages/Investment/Stocks";
import BalanceSheet from "./pages/PersonalManaging/BalanceSheet";
import FinanceInfor from "./pages/StockFinance/FinanceInfor";
import FinanceCategory from "./pages/StockFinance/FinanceCategory";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={`/`} element={<Dashboard />} />
        <Route path={`/${routeData.dashboard}`} element={<Dashboard />} />
        <Route path={`/${routeData.stocks}`} element={<Stocks />} />
        <Route path={`/${routeData.fundsPortfolio}`} element={<FundsPortfolio />} />
        <Route path={`/${routeData.funds}`} element={<Funds />} />
        <Route path={`/${routeData.balanceSheet}`} element={<BalanceSheet />} />
        <Route path={`/${routeData.financeInfor}/:stock_code`} element={<FinanceInfor />} />
        <Route path={`/${routeData.financeCategory}`} element={<FinanceCategory />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
