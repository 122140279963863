import { useState, useEffect, useCallback } from "react";
import { Table, Select, Space, Spin, Flex } from "antd";
import { getFundPortfolioStats } from "../../../../services";
import { useFundsContext } from "../../../../context/FundsContext";

const statusTypes = [
  {
    value: -1,
    label: "All",
  },
  {
    value: 1,
    label: "Active",
  },
  {
    value: 0,
    label: "Inactive",
  },
];

const fundCodes = [
  {
    fund_code: "VFMVSF",
  },
  {
    fund_code: "DCDS",
  },
  {
    fund_code: "DCDE",
  },
  {
    fund_code: "BMFF",
  },
  {
    fund_code: "BMVF",
  },
  {
    fund_code: "TCEF",
  },
  {
    fund_code: "BCF",
  },
  {
    fund_code: "MGF",
  },
  {
    fund_code: "TBF",
  },
  {
    fund_code: "VESAF",
  },
  {
    fund_code: "VEOF",
  },
  {
    fund_code: "VIBF",
  },
  {
    fund_code: "VMEEF",
  },
  {
    fund_code: "FUEVN100",
  },
];

const getTimestamp = () => {
  const startMonth = 1;
  const startYear = 2024;
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  let resutlData = [];

  for (let year = startYear; year <= currentYear; year++) {
    for (let month = startMonth; month <= 12; month++) {
      if (year === currentYear && month > currentMonth) {
        return resutlData;
      }
      resutlData.push(`${month}/${year}`);
    }
  }
};

const getMonthsHeader = () => {
  const timestamps = getTimestamp();

  const header = [];
  timestamps.forEach((e) => {
    header.push({
      title: <b className="report-component-title">{e}</b>,
      dataIndex: e,
      key: e,
      align: "center",
      ellipsis: true,
      render: (value) => {
        return value ? <b>{value}</b> : <b>x</b>;
      },
    });
  });

  return header;
};

const AdvanceFunds = () => {
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);

  const { fundsContext } = useFundsContext();
  const [fundSelected, setFundSelected] = useState(null);
  const [type, setType] = useState(-1);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getFundPortfolioStats();

      const finalData = [];
      const timestamps = getTimestamp();

      fundCodes.forEach((fundCode) => {
        const rowData = { fund_code: fundCode.fund_code };
        timestamps.forEach((time) => {
          const temp = data.find((e) => e.fund_code === fundCode.fund_code && e.month === time);
          rowData[time] = temp ? temp.stock_count : 0;
        });
        finalData.push(rowData);
      });

      setOriginalData(finalData);
      setData(finalData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let newData = [...originalData];
    if (fundSelected) {
      newData = newData.filter((e) => e.fund_code === fundSelected);
    }

    if (type === 1) {
      const activeFundCodes = [];
      fundsContext.forEach((e) => {
        if (e.status) {
          activeFundCodes.push(e.fund_code);
        }
      });

      newData = newData.filter((e) => activeFundCodes.includes(e.fund_code));
    } else if (type === 0) {
      const inactiveFundCodes = [];
      fundsContext.forEach((e) => {
        if (!e.status) {
          inactiveFundCodes.push(e.fund_code);
        }
      });

      newData = newData.filter((e) => inactiveFundCodes.includes(e.fund_code));
    }

    setData(newData);
  }, [type, fundSelected, fundsContext, originalData]);

  const monthsHeader = getMonthsHeader();

  const columns = [
    {
      title: <b className="report-component-title">I</b>,
      align: "center",
      key: "STT",
      ellipsis: true,
      width: "5%",
      fixed: "left",
      render: (_text, _record, index) => <b>{index + 1}</b>,
    },
    {
      title: <b className="report-component-title">Quỹ</b>,
      dataIndex: "fund_code",
      key: "fund_code",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
      render: (value) => <b>{value}</b>,
    },

    ...monthsHeader,
  ];

  return (
    <>
      <Flex justify="space-between">
        <Space size={12}>
          <Select
            allowClear
            showSearch
            placeholder="Tất cả quỹ đầu tư"
            onChange={(value) => setFundSelected(value)}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={fundsContext}
            style={{ width: 250 }}
          />
          <Select
            placeholder="Loại thông tin"
            value={type}
            onChange={(value) => setType(value)}
            options={statusTypes}
            style={{ width: 150 }}
          />
          {loading && <Spin />}
        </Space>

        <h4>Tổng: {data.length}</h4>
      </Flex>
      <Table columns={columns} dataSource={data} className="ant-border-space" pagination={false} />
    </>
  );
};
export default AdvanceFunds;
