import { useState, useEffect, useCallback } from "react";
import { Table, Space, DatePicker, Input, Spin, Flex, Card, Badge, Button } from "antd";
import { EyeFilled, EyeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { formatNumberWithCommas } from "../../../../services/common";
import { getFundPortfolioByMonthGroupByStock } from "../../../../services";
import { all_stocks_data } from "../../../../utils/data";
import { colors } from "../../../../utils/colors";
import StockCode from "../../../../components/common/StockCode";
const monthFormat = "MM/YYYY";

const PortfolioStatistic = ({ stock, onSelectStock }) => {
  const [loading, setLoading] = useState(true);
  const [dataGroup, setDataGroup] = useState([]);
  const [selectedStock, setSelectedStock] = useState(stock);

  // filter option
  const [time, setTime] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const defaultDate = new Date();
      defaultDate.setMonth(defaultDate.getMonth() - 1);

      const date = time ? new Date(time) : defaultDate;
      const period = `${date.getMonth() + 1}/${date.getFullYear()}`;

      const { data } = await getFundPortfolioByMonthGroupByStock(period, searchValue);

      const converedData = data.map((e, index) => {
        const stock = all_stocks_data.find((s) => s.Symbol === e.stock_code);

        return {
          key: index.toString(),
          index: index + 1,
          stock_code: e.stock_code,
          count: e.fund_code.split(",").length,
          organizations: e.fund_code,
          quantity: e.total_quantity,
          value: e.total_value,
          company_name: stock.CompanyName ?? "",
          category: stock.CategoryName ?? "",
        };
      });

      setDataGroup(converedData.sort((a, b) => b.count - a.count));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [time, searchValue]);

  const handleStockChange = (value) => {
    setSelectedStock(value);
    onSelectStock(value);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns_group = [
    {
      title: <span className="text-secondary-color">I</span>,
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: 60,
      render: (_text, _record, index) => {
        if (index <= 2) return <Badge style={{ fontWeight: 700 }} count={index + 1} color={colors[index]} />;
        else return <b>{index + 1}</b>;
      },
    },
    {
      title: <span className="text-secondary-color">Mã</span>,
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      ellipsis: true,
      render: (value) => <StockCode value={value} />,
    },
    {
      title: <span className="text-secondary-color">Ngành</span>,
      dataIndex: "category",
      key: "category",
      align: "left",
      width: 120,
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <span className="text-secondary-color">SL Quỹ</span>,
      dataIndex: "count",
      key: "count",
      align: "center",
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <span className="text-secondary-color">SLCP</span>,
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      ellipsis: true,
      render: (numberString) => <b>{formatNumberWithCommas(Math.floor(numberString / 1000))}</b>,
    },
    {
      title: <span className="text-secondary-color">Giá trị</span>,
      dataIndex: "value",
      key: "value",
      align: "center",
      ellipsis: true,
      render: (numberString) => <b>{formatNumberWithCommas(Math.floor(numberString / 1000000))}</b>,
    },
    {
      title: <span className="text-secondary-color">A</span>,
      dataIndex: "stock_code",
      align: "center",
      ellipsis: true,
      width: 60,
      render: (value) => (
        <Button
          onClick={() => handleStockChange(value)}
          size="small"
          type={selectedStock !== value ? "text" : "primary"}
          shape="circle"
          icon={selectedStock === value ? <EyeFilled /> : <EyeOutlined />}
        />
      ),
    },
  ];

  const defaultDate = new Date();
  defaultDate.setMonth(defaultDate.getMonth() - 1);

  return (
    <Card style={{ height: "70vh" }}>
      <Flex justify="space-between" align="end" style={{ marginBottom: "1.2rem" }}>
        <b className="report-component-title">Thống kê tần suất</b>
        <Space size={12}>
          {loading && <Spin />}
          <DatePicker
            defaultValue={dayjs(dayjs(defaultDate).format(monthFormat), monthFormat)}
            format={monthFormat}
            picker="month"
            onChange={(e) => setTime(e)}
          />
          <Input
            placeholder="Tìm kiếm..."
            style={{ fontWeight: "400" }}
            onPressEnter={(e) => setSearchValue(e.target.value)}
          />
        </Space>
      </Flex>
      <Table
        columns={columns_group}
        dataSource={dataGroup}
        className="ant-border-space"
        pagination={false}
        scroll={{
          y: "50vh",
        }}
      />
    </Card>
  );
};
export default PortfolioStatistic;
