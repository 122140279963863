import { useState, useEffect, useCallback } from "react";
import { Card, Table, Flex, Space, Select, Input } from "antd";
import { all_stocks_data, category_data, trade_centers_data } from "../../../utils/data";
import StockCode from "../../../components/common/StockCode";

const all_stocks_data_converted = all_stocks_data.map((stock) => {
  const TradeCenter = trade_centers_data.find((e) => e.TradeCenterId === stock.TradeCenterId);
  return {
    ...stock,
    ...TradeCenter,
    key: stock.Symbol,
  };
});

const category_data_select = category_data.map((e) => {
  return {
    value: e.categoryId,
    label: e.categoryName,
  };
});

const trade_centers_select = trade_centers_data.map((e) => {
  return {
    value: e.TradeCenterId,
    label: e.TradeCenterName,
  };
});

const Stocks = () => {
  const [data, setData] = useState(all_stocks_data_converted);

  const [tradeCenter, setTradeCenter] = useState(null);
  const [category, setCategory] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const onFilter = useCallback(() => {
    if (tradeCenter || category || searchValue) {
      let results = all_stocks_data_converted;

      if (tradeCenter) {
        results = results.filter((e) => e.TradeCenterId === tradeCenter);
      }

      if (category) {
        results = results.filter((e) => e.CategoryId && e.CategoryId === category);
      }

      if (searchValue) {
        results = results.filter((e) => e.Symbol && e.Symbol.includes(searchValue));
      }

      setData(results);
    } else {
      setData(all_stocks_data_converted);
    }
  }, [tradeCenter, category, searchValue]);

  useEffect(() => {
    onFilter();
  }, [onFilter]);

  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (_pagination, _filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: <b className="report-component-title">STT</b>,
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: "5%",
      fixed: "left",
      render: (_text, _record, index) => <b>{index + 1}</b>,
    },
    {
      title: <b className="report-component-title">Mã</b>,
      dataIndex: "Symbol",
      key: "Symbol",
      width: "10%",
      align: "left",
      ellipsis: true,
      render: (value) => <StockCode value={value} />,
      sorter: (a, b) => a.Symbol.localeCompare(b.Symbol),
      sortOrder: sortedInfo.columnKey === "Symbol" ? sortedInfo.order : null,
    },
    {
      title: <b className="report-component-title">Tên</b>,
      dataIndex: "CompanyName",
      key: "CompanyName",
      align: "left",
      ellipsis: true,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <b className="report-component-title">Ngành</b>,
      dataIndex: "CategoryName",
      key: "CategoryName",
      align: "left",
      width: "20%",
      ellipsis: true,
      sorter: (a, b) => {
        return a.CategoryName ? a.CategoryName.localeCompare(b.CategoryName) : 1;
      },
      sortOrder: sortedInfo.columnKey === "CategoryName" ? sortedInfo.order : null,
      render: (value) => <b>{value}</b>,
    },
    {
      title: <b className="report-component-title">Sàn giao dịch</b>,
      dataIndex: "TradeCenterName",
      key: "TradeCenterName",
      align: "left",
      width: "20%",
      ellipsis: true,
      sorter: (a, b) => {
        return a.TradeCenterName ? a.TradeCenterName.localeCompare(b.TradeCenterName) : 1;
      },
      sortOrder: sortedInfo.columnKey === "TradeCenterName" ? sortedInfo.order : null,
      render: (value) => <b>{value}</b>,
    },
  ];

  return (
    <Card
      bordered={false}
      title={<b className="form-title">Danh sách cổ phiếu</b>}
      styles={{ body: { padding: "0 1.8rem" } }}>
      <Flex justify="space-between">
        <Space size={12}>
          <Select
            allowClear
            showSearch
            placeholder="Sàn"
            onChange={(value) => setTradeCenter(value)}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={trade_centers_select}
            style={{ width: 200 }}
          />
          <Select
            allowClear
            showSearch
            placeholder="Ngành"
            onChange={(value) => setCategory(value)}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={category_data_select}
            style={{ width: 200 }}
          />
          <Input allowClear placeholder="Mã cổ phiếu" onPressEnter={(e) => setSearchValue(e.target.value)} />
        </Space>

        <h4>Tổng: {data.length}</h4>
      </Flex>
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        className="ant-border-space"
        pagination={{
          pageSize: 50,
          position: ["bottomCenter"],
        }}
        scroll={{
          y: "70vh",
        }}
      />
    </Card>
  );
};

export default Stocks;
