import "./index.css";
import { useNavigate } from "react-router-dom";
import { Popover, Flex, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { all_stocks_data } from "../../../utils/data";
import { useState } from "react";
import { routeData } from "../../../services/routers";

const SearchMain = () => {
  const navigate = useNavigate();
  const [resutlStocks, setResultStocks] = useState(all_stocks_data);

  const onSearchChange = (e) => {
    const searchValue = e.target.value;
    const result = all_stocks_data.filter((e) => e.Symbol.toLowerCase().includes(searchValue.toLowerCase()));
    setResultStocks(result);
  };

  return (
    <Popover
      placement="bottom"
      title={<b className="report-component-title">Danh sách mã cổ phiếu</b>}
      content={
        <Flex vertical gap={"1rem"} style={{ maxHeight: "80vh", width: "25vw", overflow: "scroll" }}>
          {resutlStocks.length > 0 &&
            resutlStocks.map((stock) => (
              <b
                onClick={() => navigate(`/${routeData.financeInfor}/${stock.Symbol}`)}
                className="item-search-result"
                key={stock.Symbol}>
                {stock.Symbol} - {stock.CompanyName}
              </b>
            ))}

          {resutlStocks.length === 0 && <i>Không có dữ liệu phù hợp</i>}
        </Flex>
      }>
      <Input
        onChange={onSearchChange}
        style={{ width: "20%" }}
        size="large"
        placeholder="Tìm kiếm mã cổ phiếu"
        prefix={<SearchOutlined />}
      />
    </Popover>
  );
};

export default SearchMain;
