import { useState } from "react";
import { Modal, Form, Select, InputNumber, Button, message, Upload, Spin, Table } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { createListFundsPortfolio, checkExistFundsPortfolio } from "../../../../services";
import { useFundsContext } from "../../../../context/FundsContext";
import { all_stocks_data } from "../../../../utils/data";
import { formatNumberWithCommas, formatToPercentage } from "../../../../services/common";
import * as XLSX from "xlsx";
import AntTable from "../../../../components/common/AntTable";

const AddPortfolios = ({ isOpenModal, onClose }) => {
  const { fundsContext } = useFundsContext();
  const [currentData, setCurrentData] = useState([]);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const width = "90%";

  const initialValues = {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  };

  const handleCloseModal = (isReload) => {
    setData([]);
    onClose(isReload);
  };

  const onSubmit = () => {
    if (currentData.length > 0) {
      message.warning("Dữ liệu đã tồn tại, vui lòng kiểm tra lại!");
      return;
    }

    setSubmiting(true);

    form
      .validateFields()
      .then(async (values) => {
        const fundData = fundsContext.find((e) => e.fund_code === values.fund);
        const convertedData = data.map((e) => {
          return {
            stock_code: e.stock_code.trim(),
            quantity: e.quantity,
            market_price: e.market_price,
            value: e.value,
            portfolio_rate: e.portfolio_rate,
            year: values.year,
            month: values.month,
            fund_code: fundData.fund_code.trim(),
            period: `${values.month}/${values.year}`,
          };
        });

        try {
          await createListFundsPortfolio(convertedData);

          message.success("Thêm dữ liệu thành công!");
          setData([]);
          setCurrentData([]);
          onCheckExistData();
        } catch (error) {
          message.error("Thêm dữ liệu thất bại!");
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      })
      .finally(() => {
        setSubmiting(false);
      });
  };

  const onCheckExistData = async () => {
    try {
      const fundCode = form.getFieldValue("fund");
      const year = form.getFieldValue("year");
      const month = form.getFieldValue("month");

      if (!fundCode || !year || !month) return;
      const period = `${month}/${year}`;

      setSubmiting(true);
      const res = await checkExistFundsPortfolio(period, fundCode);

      if (res.data.length === 0) {
        setCurrentData([]);
        return;
      }

      const converedData = res.data.map((e, index) => {
        const stock = all_stocks_data.find((s) => s.Symbol === e.stock_code);

        return {
          ...e,
          key: index.toString(),
          index: index + 1,
          fund_code: e.fund_code,
          company_name: stock.CompanyName,
          category: stock.CategoryName,
        };
      });
      setCurrentData(converedData);
    } catch (error) {
      message.error(error.message);
    } finally {
      setSubmiting(false);
    }
  };

  // select
  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // upload
  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      // Prevent the default upload behavior
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setData(jsonData);
      };

      reader.readAsBinaryString(file);
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "removed") {
        message.success(`${info.file.name} file removed successfully.`);
      }
    },
  };

  const columns = [
    {
      title: "stock_code",
    },
    {
      title: "macode",
    },
    {
      title: "quantity",
    },
    {
      title: "market_price",
    },
    {
      title: "value",
    },
    {
      title: "portfolio_rate",
    },
  ];

  const columns_current = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: "6%",
      fixed: "left",
      render: (_text, _record, index) => index + 1,
    },
    {
      title: "Mã",
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      ellipsis: true,
      width: "7%",
      fixed: "left",
    },
    {
      title: "Quỹ",
      dataIndex: "fund_code",
      key: "fund_code",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
    },
    {
      title: "Ngành",
      dataIndex: "category",
      key: "category",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
    },
    {
      title: "Tháng",
      dataIndex: "month",
      key: "month",
      align: "center",
      ellipsis: true,
      width: "7%",
      fixed: "left",
    },
    {
      title: "SLCP",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      ellipsis: true,
      render: (numberString) => formatNumberWithCommas(Math.floor(numberString / 1000)),
    },
    {
      title: "Giá TT",
      dataIndex: "market_price",
      key: "market_price",
      align: "center",
      ellipsis: true,
      render: (numberString) => formatNumberWithCommas(Math.floor(numberString / 1000)),
    },
    {
      title: "Giá trị (tỷ)",
      dataIndex: "value",
      key: "value",
      align: "center",
      ellipsis: true,
      render: (numberString) => formatNumberWithCommas(Math.floor(numberString / 1000000000)),
    },
    {
      title: "Tỉ trọng",
      dataIndex: "portfolio_rate",
      key: "portfolio_rate",
      align: "center",
      ellipsis: true,
      render: (numberString) => formatToPercentage(numberString),
    },
    {
      title: "Tên dầy đủ",
      dataIndex: "company_name",
      key: "company_name",
      align: "center",
      ellipsis: true,
    },
  ];

  return (
    <Modal
      title={<h3>Thêm mới danh mục đầu tư</h3>}
      centered
      open={isOpenModal}
      onOk={onSubmit}
      onCancel={() => handleCloseModal(false)}
      width={width}>
      <Form form={form} layout="inline" initialValues={initialValues}>
        <Form.Item
          label="Quỹ đầu tư"
          name="fund"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn quỹ!",
            },
          ]}>
          <Select
            showSearch
            placeholder="Chọn quỹ đầu tư"
            filterOption={filterOption}
            onChange={onCheckExistData}
            options={fundsContext}
            style={{ width: 250 }}
          />
        </Form.Item>
        <Form.Item
          name="year"
          label="Kỳ thống kê"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn năm!",
            },
          ]}>
          <InputNumber placeholder="Năm" onChange={onCheckExistData} />
        </Form.Item>
        <Form.Item
          name="month"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn tháng!",
            },
          ]}>
          <InputNumber placeholder="Tháng" onChange={onCheckExistData} />
        </Form.Item>
        <Form.Item
          name="data-upload"
          label="File import (excel)"
          rules={[{ required: true, message: "Vui lòng chọn file!" }]}>
          <Upload {...props}>
            <Button loading={submiting} size="small" icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>

      {data.length === 0 && currentData.length === 0 && (
        <Spin spinning={submiting}>
          <h4>Mẫu import</h4>
          <Table columns={columns} dataSource={[]} />
        </Spin>
      )}

      {currentData.length > 0 && (
        <Spin spinning={submiting}>
          <h4 style={{ color: "red", margin: "0.25rem 0" }}>
            Danh mục đã tồn tại Quỹ {form.getFieldValue("fund")} Kỳ {form.getFieldValue("month")}/
            {form.getFieldValue("year")}
          </h4>
          <Table columns={columns_current} dataSource={currentData} />
        </Spin>
      )}

      {data.length > 0 && (
        <Spin spinning={submiting}>
          <h4>Danh sách danh mục preview</h4>
          <AntTable data={data} />
        </Spin>
      )}
    </Modal>
  );
};
export default AddPortfolios;
