import { useState, useEffect, useCallback } from "react";
import { Table, Select, Input, Spin, Flex, DatePicker, Checkbox, InputNumber } from "antd";
import dayjs from "dayjs";
import { getFundPortfolioByMonthStats, getFundPortfolioGroupByStock } from "../../../../services";
import { formatNumberWithCommas, getPeriods } from "../../../../services/common";
import { useFundsContext } from "../../../../context/FundsContext";
import EChartCustom from "../../../../components/common/EChartCustom";
import useWindowSize from "../../../../hooks/useWindowSize";
import StockCode from "../../../../components/common/StockCode";
const { RangePicker } = DatePicker;
const monthFormat = "MM/YYYY";

const chartTypes = [
  {
    value: "line",
    label: "Line",
  },
  {
    value: "bar",
    label: "Bar",
  },
];

const queryTypes = [
  {
    value: "quantity",
    label: "SL CP (nghìn)",
  },
  {
    value: "value",
    label: "Giá trị (triệu)",
  },
];

const getMonthsHeader = (periods, type = "quantity") => {
  const header = [];
  for (let i = 0; i < periods.length; i++) {
    header.push({
      title: <b className="report-component-title">{periods[i]}</b>,
      dataIndex: periods[i],
      key: periods[i],
      align: "center",
      width: "7%",
      ellipsis: true,
      render: (data) => {
        const numberFlow = type === "quantity" ? 1000 : 1000000;
        if (!data)
          return (
            <Flex vertical>
              <b>0</b>
              <b style={{ color: "green", fontSize: "0.6rem" }}>--</b>
            </Flex>
          );

        let dataShow = formatNumberWithCommas(Math.floor(data.value / numberFlow));

        if (data.prev) {
          const change = Math.floor((data.value - data.prev) / numberFlow);
          if (change > 0) {
            return (
              <Flex vertical>
                <b>{dataShow}</b>
                <b style={{ color: "green", fontSize: "0.6rem" }}>+{change}</b>
              </Flex>
            );
          } else if (change === 0) {
            return (
              <Flex vertical>
                <b>{dataShow}</b>
                <b style={{ color: "green", fontSize: "0.6rem" }}>--</b>
              </Flex>
            );
          } else {
            return (
              <Flex vertical>
                <b>{dataShow}</b>
                <b style={{ color: "red", fontSize: "0.6rem" }}>{change}</b>
              </Flex>
            );
          }
        } else {
          return (
            <Flex vertical>
              <b>{dataShow}</b>
              <b style={{ color: "green", fontSize: "0.6rem" }}>+{dataShow}</b>
            </Flex>
          );
        }
      },
    });
  }

  return header;
};

const getGrowIndicator = (data, periods) => {
  let count = 0;

  for (let i = periods.length - 1; i >= 0; i--) {
    if (data[periods[i]]) {
      if (data[periods[i - 1]]) {
        if (data[periods[i]].value > data[periods[i - 1]].value) {
          count++;
        } else {
          return count;
        }
      } else {
        return count + 1;
      }
    } else return count;
  }

  return count;
};

const initChartData = (rowData, periods) => {
  const dataChart = {
    xAxis: [],
    series: [],
  };

  for (let i = 0; i < periods.length; i++) {
    dataChart.xAxis.push(periods[i]);

    if (rowData && rowData[periods[i]]) {
      dataChart.series.push(Math.floor(rowData[periods[i]].value / 1000));
    } else dataChart.series.push(1);
  }

  return dataChart;
};

const TimePortfolios = ({ isReload }) => {
  const { isMobileView } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [checked, setChecked] = useState(false);

  const { fundsContext } = useFundsContext();

  const initialDate = new Date();
  initialDate.setMonth(initialDate.getMonth() - 7); // 6 months ago

  const [times, setTimes] = useState(null);
  const [periods, setPeriods] = useState(getPeriods(initialDate.toISOString(), new Date().toISOString()));
  const [type, setType] = useState("quantity");
  const [chartType, setChartType] = useState("line");
  const [searchStockCode, setSearchStockCode] = useState("");

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const defaultDate = new Date();
      defaultDate.setMonth(defaultDate.getMonth() - 7);

      const start_date = times ? new Date(times[0]) : defaultDate;
      const end_date = times ? new Date(times[1]) : new Date();

      let res;
      if (checked) res = await getFundPortfolioGroupByStock(type, start_date, end_date, searchStockCode);
      else res = await getFundPortfolioByMonthStats(type, start_date, end_date, searchStockCode);

      const { data } = res;

      const converedData = [];

      for (let i = 0; i < data.length; i++) {
        let temp = data[i];
        for (let j = 0; j < periods.length; j++) {
          if (temp[periods[j]]) {
            temp = {
              ...temp,
              [periods[j]]: {
                value: temp[periods[j]],
                prev: 0,
              },
            };

            if (j === 0) {
              temp[periods[j]].prev = temp[periods[j]].value;
            } else if (temp[periods[j - 1]]) {
              temp[periods[j]].prev = temp[periods[j - 1]].value;
            }
          }
        }

        const growIndicator = getGrowIndicator(temp, periods);
        const fundCount = temp.fund_code.split(",").length;

        converedData.push({
          ...temp,
          growIndicator,
          index: i,
          key: i.toString(),
          fund_code: fundCount > 1 ? `(${fundCount}) ${temp.fund_code}` : temp.fund_code,
        });
      }

      setOriginalData(converedData);
      setData(converedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [checked, times, periods, type, searchStockCode]);

  useEffect(() => {
    fetchData();
  }, [isReload, fetchData]);

  const handleFilterMonthIncrement = (value) => {
    if (!value) return;
    const newDataShow = originalData.filter((e) => e.growIndicator === value);
    setData(newDataShow);
  };

  const handleRangePickerChange = (value) => {
    setTimes(value);
    const newPeriods = getPeriods(new Date(value[0]).toISOString(), new Date(value[1]).toISOString());
    setPeriods(newPeriods);
  };

  const handleOnFundChange = (value) => {
    if (!value) {
      setData(originalData);
      return;
    }
    const result = originalData.filter((item) => item.fund_code === value);
    setData(result);
  };

  const monthsHeader = getMonthsHeader(periods, type);

  const columns = [
    {
      title: <b className="report-component-title">I</b>,
      key: "index",
      align: "center",
      fixed: "left",
      width: "5%",
      ellipsis: true,
      render: (_text, _record, index) => <b>{index + 1}</b>,
    },
    {
      title: <b className="report-component-title">Mã</b>,
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      width: "7%",
      fixed: "left",
      ellipsis: true,
      render: (value) => <StockCode value={value} />,
    },
    {
      title: <b className="report-component-title">Quỹ</b>,
      dataIndex: "fund_code",
      key: "fund_code",
      fixed: "left",
      width: "7%",
      ellipsis: true,
      render: (data) => <b className="one-line-ellipsis">{data}</b>,
    },
    ...monthsHeader,
    {
      title: <b className="report-component-title">I</b>,
      dataIndex: "growIndicator",
      key: "growIndicator",
      align: "center",
      fixed: "right",
      width: "5%",
      ellipsis: true,
      render: (data) => (
        <Flex vertical>
          <b style={{ color: "#015096" }}>{data}</b>
          <b style={{ color: "white", fontSize: "0.6rem" }}>0</b>
        </Flex>
      ),
      sorter: (a, b) => a.growIndicator - b.growIndicator,
    },
    {
      title: "Chart",
      dataIndex: checked ? "stock_code" : "index",
      key: checked ? "stock_code" : "index",
      align: "center",
      ellipsis: true,
      fixed: "right",
      width: "15%",
      render: (item) => {
        let rowData = [];
        if (checked) {
          rowData = data.find((e) => e.stock_code === item);
        } else {
          rowData = originalData[item];
        }

        const dataChart = initChartData(rowData, periods);

        return (
          <EChartCustom
            data={dataChart}
            type={chartType}
            style={{ height: isMobileView ? "7rem" : "5rem", width: isMobileView ? "20vw" : "10vw" }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Flex gap={isMobileView ? 6 : 12} align="center" style={{ flexWrap: "wrap", width: "100%", margin: "0.75rem 0" }}>
        <RangePicker
          defaultValue={[
            dayjs(dayjs(initialDate).format(monthFormat), monthFormat),
            dayjs(dayjs(new Date()).format(monthFormat), monthFormat),
          ]}
          format={monthFormat}
          picker="month"
          onChange={handleRangePickerChange}
        />
        <Select
          allowClear
          showSearch
          placeholder="Quỹ đầu tư"
          disabled={checked}
          onChange={handleOnFundChange}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          options={fundsContext}
          style={{ width: 220 }}
        />

        <Input
          placeholder="Mã cổ phiếu"
          style={{ width: 150, fontWeight: "400" }}
          onPressEnter={(e) => setSearchStockCode(e.target.value)}
        />
        <Select
          placeholder="Loại thông tin"
          value={type}
          onChange={(value) => setType(value)}
          options={queryTypes}
          style={{ width: 150 }}
        />
        <Select
          placeholder="Loại biểu đồ"
          defaultValue={"line"}
          onChange={(value) => setChartType(value)}
          options={chartTypes}
          style={{ width: 100 }}
        />

        <InputNumber placeholder="Tăng liên tục" style={{ width: 120 }} onChange={handleFilterMonthIncrement} />
        <Checkbox checked={checked} onChange={() => setChecked((prev) => !prev)}>
          Nhóm theo mã
        </Checkbox>
        <Flex gap={12} align="center" justify="end" style={{ flexGrow: 1 }}>
          {loading && <Spin />}
          <span style={{ fontWeight: 600 }}>Tổng: {data.length}</span>
        </Flex>
      </Flex>
      <Table
        columns={columns}
        dataSource={data}
        className="ant-border-space"
        pagination={true}
        scroll={{
          x: "max-content",
          y: "80vh",
        }}
      />
    </>
  );
};
export default TimePortfolios;
